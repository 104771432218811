import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import ProductPreview from '../../../components/ProductPreview/ProductPreview'
import Text from '../../../components/Text'

import debounce from '../../../utils/debounce'
import t from '../../../utils/translate'

import RankingMixStore from '../../../stores/RankingMixStore'
import { UIStore } from '../../../stores'

const PreviewSection = ({ type }) => {
  const [previewLanguage, setPreviewLanguage] = useState(
    UIStore.enterpriseConfiguration.defaultLanguage
  )
  const onChangePreviewLanguage = (lang) => {
    setPreviewLanguage(lang)
    RankingMixStore.fetchProductPreview(
      { ...RankingMixStore.previewSettings, language: lang },
      'language'
    )
  }
  const handlePreviewChange = debounce((search, changedVariable) => {
    RankingMixStore.fetchProductPreview(search, changedVariable)
  }, 500)

  return (
    <>
      <Text
        element="p"
        className="ranking-mix__preview-headline"
        weight="bold"
        size="echo"
      >
        {t('Preview the Rankingmix for…')}
      </Text>
      <ProductPreview
        onChangePreviewGroup={() => {}}
        type={type}
        singleRow
        withoutSearchText={t(
          'Enter a search word or choose a category above to see a product list to preview your selected ranking mix.'
        )}
        loading={RankingMixStore.previewLoading}
        products={RankingMixStore.previewProducts}
        onChange={handlePreviewChange}
        maxPage={RankingMixStore.maxPreviewPage}
        currentPage={
          RankingMixStore.previewPage
            ? RankingMixStore.previewPage
            : RankingMixStore.previewPage + 1
        }
        maxProductsPerPage={RankingMixStore.MAX_PRODUCTS_IN_PREVIEW}
        scoreShown
        typeSelectShown={false}
        previewLanguage={previewLanguage}
        onChangePreviewLanguage={onChangePreviewLanguage}
      />
    </>
  )
}

export default observer(PreviewSection)
