import { useEffect, useState } from 'react'
import FlipMove from 'react-flip-move'
import classNames from 'classnames'

import DocumentSelect from '../DocumentSelect'
import Pagination from '../Pagination'
import Container from '../Container'
import TextInput from '../TextInput'
import Spinner from '../Spinner'
import Select from '../Select'
import Switch from '../Switch'
import Text from '../Text'

import ProductRow from './ProductRow'
import TagsProductInput from '../TagsProductInput'

import { USERS } from '../../stores/PersonalizationStore/mocks'
import { RecommendationStore, UIStore } from '../../stores'

import t from '../../utils/translate'

import './productPreview.styl'
import BannerRow from './BannerRow'
import { formatLanguageKey } from '../../utils'

const Empty = ({ emptyText, loading = false }) => {
  return (
    <Container level={-2} className="product-preview__empty">
      {loading && <Spinner />}
      <Text element="p" size="bravo" weight="medium">
        {emptyText}
      </Text>
    </Container>
  )
}

const ProductPreview = ({
  machineLearningShown = false,
  usersSelectShown = false,
  weatherSelectShown = false,
  singleRow = false,
  products = [],
  onChange = () => {},
  withoutSearchText = '',
  emptyText = 'No products found',
  maxPage = 0,
  currentPage = 0,
  maxProductsPerPage = 0,
  loading = false,
  headline = '',
  scoreShown = false,
  type = 'product',
  typeSelectShown = true,
  tagsInputShown = false,
  hideSelectDocument,
  hideSearchPharseInput,
  previewLanguage,
  onChangePreviewLanguage,
  previewGroup,
  onChangePreviewGroup,
}) => {
  const [internalLoading, setInternalLoading] = useState(false)
  const [user, setUser] = useState(0)
  const [weather, setWeather] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [previewType, setPreviewType] = useState('product')
  const [categoryId, setCategoryId] = useState('')
  const [manufacturerId, setManufacturerId] = useState('')
  const [withMachineLearning, setWithMachineLearning] = useState(false)
  const [productId, setProductId] = useState([])
  const [previewGroupState, setPreviewGroupState] = useState(previewGroup)

  useEffect(() => {
    handlePreviewTypeChange(type)
    // eslint-disable-next-line
  }, [type])

  useEffect(() => {
    setInternalLoading(loading)
  }, [loading])

  const handleUserChange = (updatedUser) => {
    setUser(updatedUser)

    handleChange({ user: USERS[updatedUser] })
  }

  const handleChangePreviewGroup = (updated) => {
    setPreviewGroupState(updated)
    onChangePreviewGroup(updated)

    handleChange({ group: updated })
  }

  const handleWeatherChange = (updatedWeather) => {
    setWeather(updatedWeather)

    handleChange({ weather: updatedWeather })
  }

  const handlePreviewTypeChange = (updatedPreviewType) => {
    setPreviewType(updatedPreviewType)
    setSearchValue('')
    setCategoryId('')
    setManufacturerId('')

    handleChange({
      previewType: updatedPreviewType,
      searchValue: '',
      categoryId: '',
      manufacturerId: '',
    })
  }

  const handleSearchValueInputChange = (event) => {
    setSearchValue(event.target.value)

    handleChange({ searchValue: event.target.value })
  }

  const handleCategoryChange = (updatedCategoryId, selectedDocument) => {
    setCategoryId(updatedCategoryId)

    handleChange({
      categoryId: updatedCategoryId,
      selectedDocument,
    })
  }

  const handleManufacturerChange = (
    updatedManufacturerId,
    selectedDocument
  ) => {
    setManufacturerId(updatedManufacturerId)

    handleChange({
      manufacturerId: updatedManufacturerId,
      selectedDocument,
    })
  }

  const handleProductIdChange = (updatedproductId) => {
    setProductId(updatedproductId)

    handleChange({ productId: updatedproductId })
  }

  const handleMachineLearningChange = () => {
    setWithMachineLearning(!withMachineLearning)

    handleChange({ withMachineLearning: !withMachineLearning })
  }

  const handlePageSwitch = (newPage) => {
    handleChange({ page: newPage })
  }

  const handleChange = (updatedValue) => {
    setInternalLoading(true)
    const changedVariable = Object.keys(updatedValue)[0]

    onChange(
      {
        searchValue,
        previewType,
        categoryId,
        manufacturerId,
        withMachineLearning,
        page: currentPage,
        user: USERS[user],
        weather,
        group: previewGroupState,
        ...updatedValue,
      },
      changedVariable
    )

    if (
      (updatedValue.searchValue === '' && categoryId === '') ||
      (updatedValue.categoryId === '' && searchValue === '') ||
      (updatedValue.manufacturerId === '' && searchValue === '')
    ) {
      setInternalLoading(false)
    }
  }

  const hasSearch = searchValue !== '' || categoryId !== ''
  const listOffset = maxProductsPerPage * (currentPage - 1)

  return (
    <div
      className={classNames('product-preview', {
        'product-preview--single-row': singleRow,
      })}
    >
      <div className="product-preview__controls">
        {headline && (
          <Text weight="bold" size="delta">
            {headline}
          </Text>
        )}
        {usersSelectShown && (
          <Select
            className="product-preview__user-select"
            value={user}
            title={t('User')}
            options={USERS.map((userOption, idx) => ({
              label: userOption.name,
              value: idx,
            }))}
            onChange={handleUserChange}
          />
        )}
        {weatherSelectShown && (
          <Select
            className="product-preview__user-select"
            value={weather}
            title={t('Weather')}
            options={[
              { label: t('Ignore'), value: '' },
              { label: t('Rainy'), value: 'rgroup1' },
              { label: t('Sunny'), value: 'rgroup0' },
            ]}
            onChange={handleWeatherChange}
          />
        )}
        {typeSelectShown && (
          <Select
            className={classNames('product-preview__page-type-select', {
              'product-preview__page-type-select--no-margin':
                !headline && !usersSelectShown,
            })}
            value={previewType}
            title={t('Page type')}
            options={[
              { label: t('Search results'), value: 'product' },
              { label: t('Category'), value: 'category' },
            ]}
            onChange={handlePreviewTypeChange}
          />
        )}

        <div
          className={classNames('product-preview__search-input', {
            'product-preview__search-input--no-margin': !typeSelectShown,
          })}
        >
          <div className="product-preview__inputs-row">
            {previewLanguage &&
              onChangePreviewLanguage &&
              previewType !== 'product' && (
                <Select
                  title={t('Preview language')}
                  options={UIStore.languages.map((language) => ({
                    value: language,
                    label: formatLanguageKey(language),
                  }))}
                  value={previewLanguage}
                  dropdownMatchSelectWidth={false}
                  onChange={onChangePreviewLanguage}
                />
              )}
            {onChangePreviewGroup !== undefined &&
              UIStore.productGroups.length > 0 && (
                <Select
                  style={{ marginBottom: 10 }}
                  title={t('Preview group')}
                  options={UIStore.productGroups.map((group) => ({
                    value: group,
                    label: group,
                  }))}
                  value={previewGroupState}
                  dropdownMatchSelectWidth={false}
                  onChange={handleChangePreviewGroup}
                  allowClear
                />
              )}
          </div>

          {previewType === 'recommendation' && tagsInputShown && (
            <TagsProductInput
              languageSelect={
                <Select
                  options={UIStore.languages.map((language) => ({
                    value: language,
                    label: language.toUpperCase(),
                  }))}
                  flexible
                  value={
                    RecommendationStore.previewLanguage ||
                    UIStore.enterpriseConfiguration.defaultLanguage
                  }
                  dropdownMatchSelectWidth={false}
                  onChange={RecommendationStore.onChangePreviewLanguage}
                />
              }
              value={productId}
              onChange={handleProductIdChange}
              searchedProducts={RecommendationStore.searchList}
              allProductByIds={RecommendationStore.searchListByIds}
              onSearch={(searchPhrase) =>
                RecommendationStore.fetchSearchList({
                  searchPhrase,
                  shopId: UIStore.shopId,
                  language: RecommendationStore.previewLanguage,
                })
              }
            />
          )}
          {previewType === 'product' &&
            !hideSearchPharseInput &&
            !onChangePreviewLanguage && (
              <TextInput
                label={typeSelectShown ? undefined : t('Search results')}
                value={searchValue}
                rounded
                icon="magnifying-glass"
                placeholder={t('Enter searchphrase...')}
                onChange={handleSearchValueInputChange}
              />
            )}

          {previewType === 'product' &&
            !hideSearchPharseInput &&
            onChangePreviewLanguage && (
              <div className="search-monitoring__check-search-term-wrapper">
                <Text>{t('Search results')}</Text>
                <div className="search-monitoring__check-search-term-input-wrapper">
                  <div className="search-monitoring__check-search-term-input">
                    <Select
                      value={previewLanguage}
                      translateLabel
                      options={UIStore.languages.map((language) => ({
                        value: language,
                        label: language.toUpperCase(),
                      }))}
                      flexible
                      dropdownMatchSelectWidth={false}
                      onChange={onChangePreviewLanguage}
                    />
                    <TextInput
                      value={searchValue}
                      rounded
                      icon="magnifying-glass"
                      onChange={handleSearchValueInputChange}
                    />
                  </div>
                </div>
              </div>
            )}

          {previewType === 'category' && !hideSelectDocument && (
            <DocumentSelect
              title={typeSelectShown ? undefined : t('Categories')}
              placeholder={t('Enter a category ...')}
              type="category"
              showSearch
              // Additional check is here required so that the placeholder value
              // is displayed when the value has 0 length.
              value={categoryId === '' ? undefined : categoryId}
              onChange={handleCategoryChange}
            />
          )}
          {previewType === 'manufacturer' && !hideSelectDocument && (
            <DocumentSelect
              title={typeSelectShown ? undefined : t('Manufacturer')}
              placeholder={t('Enter a manufacturer ...')}
              type="manufacturer"
              showSearch
              // Additional check is here required so that the placeholder value
              // is displayed when the value has 0 length.
              value={manufacturerId === '' ? undefined : manufacturerId}
              onChange={handleManufacturerChange}
            />
          )}
        </div>
      </div>
      <div className="product-preview__products">
        {machineLearningShown && (
          <Switch
            className="product-preview__machine-learning-switch"
            checked={withMachineLearning}
            type="horizontal"
            title={
              <Text weight="bold" size="delta">
                {t('with Machine Learning')}
              </Text>
            }
            onChange={handleMachineLearningChange}
          />
        )}
        <div className="product-preview__list-header">
          <div className="product-preview__list-column product-preview__position">
            <Text variant="book">Pos.</Text>
          </div>
          <div className="product-preview__list-column  product-preview__product">
            <Text variant="book">
              {type === 'product'
                ? t('Product / EAN')
                : `${type.charAt(0).toUpperCase() + type.slice(1)} / ID`}
            </Text>
          </div>
          {scoreShown && (
            <div className="product-preview__list-column  product-preview__score">
              <Text variant="book">{t('Score')}</Text>
            </div>
          )}
        </div>
        {products.length === 0 && !hasSearch && (
          <Empty emptyText={withoutSearchText} />
        )}
        {products.length === 0 && !internalLoading && hasSearch && (
          <Empty emptyText={t(emptyText)} />
        )}
        {products.length === 0 && hasSearch && internalLoading && (
          <Empty loading />
        )}

        <FlipMove typeName={'div'}>
          {products.map((product, index) => {
            if (product.type === 'banner') {
              return (
                <BannerRow
                  key={product.id}
                  position={listOffset + index + 1}
                  banner={product}
                />
              )
            }

            return (
              <ProductRow
                key={product.id}
                position={listOffset + index + 1}
                product={product}
                scoreShown={scoreShown}
              />
            )
          })}
        </FlipMove>
        {products.length > 0 && maxPage > 1 && (
          <Pagination
            currentPage={currentPage}
            maxPage={maxPage}
            onPageSwitch={handlePageSwitch}
          />
        )}
      </div>
    </div>
  )
}

export default ProductPreview
